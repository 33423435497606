
import { Component, Prop, Vue } from "vue-property-decorator";

import CustomerBase from '@/common/customer.base'
import reportDataUtils from '../ReportDataUtils'
import * as XLSX from 'xlsx';
//@ts-nocheck
@Component({
  components: {
    
  }
})
export default class ReportChartView extends CustomerBase {
  @Prop()
  data: any[];
  @Prop()
  range: any;
  fields= [
          { key: 'time',            label: 'Time(Local)', sortable: true },
          { key: 'tenantName', label: 'Firm', sortable: true },
          { key: 'reportName',label: 'Report',  sortable: true },
          { key: 'isSecondaryDb',label: 'Secondary',  sortable: true },
        ]
        totalRows= 1;
        currentPage= 1;
        perPage= 100;
        sortBy='time'
        sortDesc=true
  created() {
   this.totalRows=this.data.length
  }
  
  
  exportToExcel() {
  // Format headers
  const headers = this.fields.map((field) => field.label);

  // Create array for raw data
  const excelData = [headers];

  // Add data rows
  this.data.forEach((item) => {
    const row = [
      this.formatDate(item.time), // Formatted date
      item.tenantName,
      item.reportName,
      item.isSecondaryDb ? "Yes" : "No", // Convert boolean to Yes/No
    ];
    excelData.push(row);
  });

  // Create workbook and add Source Data sheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(excelData);
  XLSX.utils.book_append_sheet(wb, ws, "Source Data");

  // ==== SUMMARY: BY REPORT ====
  const reportCounts = this.data.reduce((acc, item) => {
    acc[item.reportName] = (acc[item.reportName] || 0) + 1;
    return acc;
  }, {});

  const sortedReports = Object.entries(reportCounts) //@ts-ignore
    .sort((a, b) => b[1] - a[1])
    .map(([name, count]) => [name, count]);

  sortedReports.unshift(["Report Name", "Total Count"]);
  const wsReports = XLSX.utils.aoa_to_sheet(sortedReports);
  XLSX.utils.book_append_sheet(wb, wsReports, "By Report");

  // ==== SUMMARY: BY FIRM ====
  const firmCounts = this.data.reduce((acc, item) => {
    acc[item.tenantName] = (acc[item.tenantName] || 0) + 1;
    return acc;
  }, {});

  const sortedFirms = Object.entries(firmCounts)//@ts-ignore
    .sort((a, b) => b[1] - a[1])
    .map(([name, count]) => [name, count]);

  sortedFirms.unshift(["Firm Name", "Total Count"]);
  const wsFirms = XLSX.utils.aoa_to_sheet(sortedFirms);
  XLSX.utils.book_append_sheet(wb, wsFirms, "By Firm");

  // ==== SUMMARY: BY FIRM & REPORT (CORRECTED SORTING) ====
  const firmReportCounts = this.data.reduce((acc, item) => {
    const key = `${item.tenantName}|||${item.reportName}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  let groupedByFirm = {};
  let maxReportRunByFirm = {};

  // Step 1: Group reports under firms and find max report runs per firm
  Object.entries(firmReportCounts).forEach(([key, count]) => {
    const [tenantName, reportName] = key.split("|||");
    if (!groupedByFirm[tenantName]) groupedByFirm[tenantName] = [];

    groupedByFirm[tenantName].push([reportName, count]);

    if (!maxReportRunByFirm[tenantName] || count > maxReportRunByFirm[tenantName]) {
      maxReportRunByFirm[tenantName] = count;
    }
  });

  // Step 2: Sort firms by their max single report run (descending)
  let sortedFirmReport = Object.entries(groupedByFirm)
    .map(([firm, reports]) => ({
      firm,//@ts-ignore
      reports: reports.sort((a, b) => b[1] - a[1]), // Sort reports inside each firm
      maxRun: maxReportRunByFirm[firm], // Highest single report run
    }))
    .sort((a, b) => b.maxRun - a.maxRun); // Sort firms by max single report run

  // Prepare data for Excel
  let finalFirmReportSheet = [["Firm Name", "Report Name", "Total Runs"]];

  sortedFirmReport.forEach(({ firm, reports }) => {
    reports.forEach(([report, count]) => {
      finalFirmReportSheet.push([firm, report, count]);
    });
  });

  const wsFirmReport = XLSX.utils.aoa_to_sheet(finalFirmReportSheet);
  XLSX.utils.book_append_sheet(wb, wsFirmReport, "By Firm & Report");

  // ==== SAVE FILE ====
  XLSX.writeFile(wb, "report_data.xlsx");
}
formatDate(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  return date.toLocaleString();
}

  

 

}
