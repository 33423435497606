
  import {
    Component,
    Prop,
    Vue,
    Watch
  } from "vue-property-decorator";
  
  import {
    firmDataService
  } from "../../utils/http";
  
  import CustomerBase from '@/common/customer.base'
  import DataStats from './DataStats.vue'
  
  interface SummaryItem {
    key: string;
    label: string;
    rawTotal: number;
    formattedTotal: string;
    isByteValue: boolean;
  }
  
  @Component({
    name: 'data-stats',
    components: {
      'data-stats': DataStats
    }
  })
  export default class AdministratorEmails extends CustomerBase {
    @Prop()
    firmId: string = null;
    
    currentFirmDetailsId: String = null;
    summaryData: SummaryItem[] = [];
    
    fields = [
      {key: 'urlCode', label: 'Firm', sortable: true},
      {key: 'date', label: 'Calculated.On', sortable: true},
      {key: 'numberOfClients', label: 'Clients', sortable: true},
      {key: 'numberOfEmployees', label: 'Employees', sortable: true},
      {key: 'numberOfOffices', label: 'Offices', sortable: true},
      {key: 'numberOfWorkcodes', label: 'Workcodes', sortable: true},
      // {key:'numberOfContacts', sortable: true },
      {key: 'numberOfDuedates', label: 'Legacy. Duedates', sortable: true},
      {key: 'numberOfWorkflowEntires', label: 'Duedates', sortable: true},
      {key: 'numberOfInvoices', label: 'Invoices', sortable: true},
      {key: 'numberOfPayments', label: 'Payments', sortable: true},
      {key: 'numberOfTimeEntries', label: 'Times', sortable: true},
      {key: 'numberOfTimers', label: 'Timers', sortable: true},
      {key: 'numberOfClientEmails', label: 'Emails', sortable: true},
      // {key:'numberOfPortalClients', sortable: true },
      {key: 'numberOfPortalFiles', label: 'Portal Files', sortable: true},
      // {key:'numberOfThirdPartyIntegrations', sortable: true },
      {key: 'portalBandwidth', label: 'Portal Bandwidth', sortable: true},
      {key: 'portalStorage', label: 'Portal Storage', sortable: true},
    ];
    
    public stats: any[] = [];
    
    created() {
      this.getData();
    }
    
    @Watch('stats')
    onStatsChanged() {
      this.calculateSummary();
    }
    
    // Pre-calculate all summary data
    calculateSummary() {
      if (!this.stats || this.stats.length === 0) {
        this.summaryData = [];
        return;
      }
      
      const byteFields = ['portalBandwidth', 'portalStorage'];
      
      // Filter out date and urlCode fields
      const summaryFields = this.fields.filter(f => 
        f.key !== 'date' && f.key !== 'urlCode'
      );
      
      // Calculate and format all totals
      this.summaryData = summaryFields.map(field => {
        const isByteValue = byteFields.includes(field.key);
        const rawTotal = this.stats.reduce((total, item) => {
          return total + (item[field.key] || 0);
        }, 0);
        
        let formattedTotal;
        if (isByteValue) {
          // Using the nicebytes filter (must be available globally)
          formattedTotal = this.$options.filters.nicebytes(rawTotal);
        } else {
          // Using the niceNumber filter (must be available globally)
          formattedTotal = this.$options.filters.niceNumber(rawTotal);
        }
        
        return {
          key: field.key,
          label: field.label,
          rawTotal,
          formattedTotal,
          isByteValue
        };
      });
    }
    
    getData() {
      this.isBusy(true);
      
      firmDataService.dataStats(this.firmId).then((result) => {
        this.isBusy(false);
        this.stats = result;
        this.calculateSummary(); // Calculate summary after data loads
      }).catch((err) => this.isBusy(false));
    }
    
    exportToExcel() {
      this.exportTableToExcel("stats-table");
    }
  }
